.counter-component {
    display: flex;

    &__btn__left,
    &__btn__center,
    &__btn__right {
        background-color: transparent;
        border: 1px solid #333;
        width: 30px;
    }

    &__btn__left {
        border-radius: 5px 0 0 5px;
    }

    &__btn__center {
        border-left: none;
        border-right: none;
        cursor: default !important;
        background-color: #e3e3e3;
        color: #333;
        width: 40px;
        font-weight: bold;
    }

    &__btn__right {
        border-radius: 0 5px 5px 0;
    }
}
