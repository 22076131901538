.border-b-default {
    border-bottom: 2px solid map-get($map: $colors, $key: "dark" );
}

.border-t-default {
    border-top: 2px solid map-get($map: $colors, $key: "dark" );
}

.border-l-default {
    border-left: 2px solid map-get($map: $colors, $key: "dark" );
}

.border-r-default {
    border-right: 2px solid map-get($map: $colors, $key: "dark" );
}

.border-default {
    border: 2px solid map-get($map: $colors, $key: "dark" );
}

.border-b-3-default {
    border-bottom: 3px solid map-get($map: $colors, $key: "dark" );
}

.border-t-3-default {
    border-top: 3px solid map-get($map: $colors, $key: "dark" );
}

.border-l-3-default {
    border-left: 3px solid map-get($map: $colors, $key: "dark" );
}

.border-r-3-default {
    border-right: 3px solid map-get($map: $colors, $key: "dark" );
}

.border-3-default {
    border: 3px solid map-get($map: $colors, $key: "dark" );
}


.capitalize {
    text-transform: capitalize;
}






// RSUITE

.rs-checkbox-checker {
    color: white;
}