.form-class {
    input.form-class[type="text"], input.form-class[type="text"]:focus {
        background-color: #000;
        border-color: #2e2e2e;
        border-width: 2px;
        color: #e3e3e3;
        box-shadow: none;
        

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #2e2e2e;
            opacity: 1; /* Firefox */
          }
    }

    input.form-class[type="text"]:hover {
        border-color: #39414e;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba(101, 103, 106, 0.25);
    }

    input.form-class[type="text"] + span {
        background-color: transparent;
        color: #e3e3e3;
        position: absolute;
        top: 6px;
        left: 10px;
        font-size: 17px;
    }

    input.form-class[type="text"].icon {
        padding-left: 35px;
    }
}


// Buttons

.btn-outline-default {
    border: 1px solid #1E2136;

    &:hover {
        background-color: #1E2136;
        border: 1px solid #1E2136;
    }
}