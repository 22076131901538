.container-search {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #00000075;
    z-index: 7;
    display: flex;
    justify-content: center;

    &__wrap{
        width: 100%;
        max-width: 500px;
        margin-top: 100px;
        background-color: #fff;
        border-radius: 5px;
        padding: 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: fit-content;
    }

}

.container-box-search {
    background: #1b1f22;
    border-radius: 6px;
    box-shadow:
        inset 1px 1px 0 0 #2c2e40,
        0 1px 3px 0 #090a0c;
    flex-direction: column;
    margin: 60px auto auto;
    position: relative;
    width: 500px;
    max-width: 90%;

    &__wrap {
        padding: 20px;
    }

    &__footer {
        align-items: center;
        background: #1e2136;
        border-radius: 0 0 8px 8px;
        box-shadow: inset 0 1px 0 0 rgba(73, 76, 106, .5), 0 -4px 8px 0 rgba(0, 0, 0, .2);
        display: flex;
        flex-direction: row-reverse;
        flex-shrink: 0;
        justify-content: space-between;
        position: relative;
        user-select: none;
        width: 100%;
        z-index: 300;
    }
}