.card-component {
    &__counter {
        position: absolute;
        width: 100%;
        bottom: 0;
        background-color: #00000080;
        justify-content: space-around;
        display: flex;
        transform: translateY(100%);
        transition: transform 0.3s ease-in-out;
    }

    &__counter.show {
        transform: translateY(0);
    }
}
