@import "bootstrap/scss/bootstrap";
@import "rsuite/dist/rsuite.min.css";
@import "react-alice-carousel/lib/alice-carousel.css";
@import "animate.css";
@import "./variable";
@import "./components";
@import "./helpers";

@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/Roboto-Regular.ttf");
}

@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/Roboto-Bold.ttf");
    font-weight: bold;
}

@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/Roboto-Black.ttf");
    font-weight: 300;
}

html,
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    background-color: map-get($map: $bg-colors, $key: "bg");
    overflow-x: hidden;
}

#root {
    height: 100%;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #5c5c5c;
    border-radius: 5px;
    box-shadow:
        0 1px 3px 0 #090a0c,
        inset 1px 1px 0 0 #6e6f7a;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.shadow-default {
    box-shadow:
        inset 1px 1px 0 0 #2c2d3e,
        0 1px 3px 0 #090a0c;
}
